import React from 'react'
import ViewUser from './ViewUser'
import ViewGroup from './ViewGroup'
import {Link, linkHandler, route} from 'router'
import Loading from 'Components/Loading'
import {Query} from 'react-apollo'
import {name as formatName} from 'utils/format'
import {
  GET_COMPANIES,
  GET_ME,
  GET_ME_AND_USERS,
  GET_GROUPS_BY_COMPANY,
  GET_GROUP_BY_ID,
  GET_USER_BY_ID,
  GET_COMPANY_BY_ID
} from './queries'
import AddButton from 'Components/AddButton'
import {connect} from 'react-redux'
import {
  setDirectoryCompany,
  setDirectoryPath,
  setDirectoryGroup,
  setRefetchGroupsByCompany
} from 'store/actions'
import ViewCompany from './ViewCompany'
import ViewCompanyWide from './ViewCompanyWide'

import './index.scss'

const mapStateToProps = (state) => ({
  companyID: state.directory.companyID,
  groupID: state.directory.groupID,
  refetchGroupsByCompany: state.directory.refetchGroupsByCompany
})
const mapDispatchToProps = (dispatch) => ({
  setCompany: (id) => {
    dispatch(setDirectoryCompany(id))
    dispatch(setDirectoryPath(''))
  },
  setGroup: (id) => {
    dispatch(setDirectoryGroup(id))
  },
  setRefetchGroupsByCompany: (refetch) => {
    dispatch(setRefetchGroupsByCompany(refetch))
  }
})

export default connect(mapStateToProps)(
  ({
    userID,
    companyID,
    groupIDFromRoute,
    refetchGroupsByCompany,
    companyIDFromRoute
  }) => (
    <Query query={GET_ME} notifyOnNetworkStatusChange={true}>
      {({loading, error, data, networkStatus}) => {
        if (networkStatus === 4) return <Loading />
        if (loading) return <Loading />
        if (error) return <div>error</div>
        const isSuperUser = data && data.me && data.me.isSuperUser
        const isSpecificUser = data && data.me && (data.me.id === 12034 || data.me.id === 11394)

        if (userID) {
          return userID ? (
            <Query query={GET_USER_BY_ID} variables={{id: userID}}>
              {({loading, error, data, networkStatus}) => {
                if (networkStatus === 4) return <Loading />
                if (loading) {
                  return <Loading />
                }
                if (error) {
                  return <div>error</div>
                }
                if (data.user === null) {
                  return <div>error</div>
                }
                return (
                  <ViewUser
                    user={data.user}
                    allGroups={data.user.company.groups}
                    isSuperUser={isSuperUser}
                    refetch={refetchGroupsByCompany}
                  />
                )
              }}
            </Query>
          ) : isSuperUser && (!!companyID || companyID === 0) ? (
            <ViewCompany companyID={companyID} isSuperUser={isSuperUser} />
          ) : null
        }

        if (groupIDFromRoute) {
          return groupIDFromRoute ? (
            <Query query={GET_GROUP_BY_ID} variables={{id: groupIDFromRoute}}>
              {({loading, error, data, networkStatus}) => {
                if (networkStatus === 4) return <Loading />
                if (loading) {
                  return <Loading />
                }
                if (error) {
                  return <div>error</div>
                }
                if (data.group === null) {
                  return <div>error</div>
                }
                return (
                  <ViewGroup
                    group={data.group}
                    allDevices={data.group.company.inclusiveDevices}
                    allUsers={data.group.company.users}
                    isSuperUser={isSuperUser}
                  />
                )
              }}
            </Query>
          ) : isSuperUser && companyID ? (
            <ViewCompany companyID={companyID} isSuperUser={isSuperUser} />
          ) : null
        }

        return !!companyIDFromRoute || companyIDFromRoute === 0 ? (
          <Query query={GET_COMPANY_BY_ID} variables={{id: companyIDFromRoute}}>
            {({loading, error, data, networkStatus}) => {
              if (networkStatus === 4) return <Loading />
              if (loading) {
                return <Loading />
              }
              if (error) {
                return <div>error</div>
              }
              if (data.company === null) {
                return <div>error</div>
              }
              return (
                <ViewCompanyWide
                  company={data.company}
                  isSuperUser={isSuperUser}
                />
              )
            }}
          </Query>
        ) : (isSuperUser && isSpecificUser) && companyID ? (
          <ViewCompany companyID={companyID} isSuperUser={isSuperUser} />
        ) : null
      }}
    </Query>
  )
)

export const Menu = () => (
  <Query query={GET_ME_AND_USERS}>
    {({loading, error, data, networkStatus}) => {
      if (networkStatus === 4) return <Loading />
      if (loading) return <Loading />
      if (error) return <div>error</div>
      const isSuperUser = data && data.me && data.me.isSuperUser
      const isSpecificUser = data && data.me && (data.me.id === 12034 || data.me.id === 11394)

      return (
        <div className="team-menu">
          {isSuperUser ? (
            <div className="admin-add-buttons" style={{display: 'contents'}}>
              <AddButton onClick={linkHandler('/addUser')} text="Add User" />
              <AddButton onClick={linkHandler('/addGroup')} text="Add Group" />
              <AddButton
                onClick={linkHandler('/addCompany')}
                text="Add Company"
              />
            </div>
          ) : (
            <div className="add-buttons" style={{display: 'contents'}}>
              <AddButton onClick={linkHandler('/addUser')} text="Add User" />
              <AddButton onClick={linkHandler('/addGroup')} text="Add Group" />
            </div>
          )}
          <div className="list">
            {(isSuperUser || isSpecificUser) ? (
              <AdminGroupsList
                // groups={(data && data.groups) || []}
                company={data && data.me.company}
                viewInactiveCompanies={data && data.me.viewInactiveCompanies}
                // users={(data && data.users) || []}
                isSuperUser={isSuperUser}
                isSpecificUser={isSpecificUser}
              />
            ) : (
              <GroupsList
                // groups={(data && data.groups) || []}
                company={data && data.me.company}
                users={(data && data.me.company.users) || []}
                isSuperUser={isSuperUser}
              />
            )}
          </div>
        </div>
      )
    }}
  </Query>
)

const UsersList = ({users, refetch, setRefetchGroupsByCompany}) => {
  const compare = (a, b) => {
    if (a.lastName === null) {
      return 1
    }
    if (b.lastName === null) {
      return -1
    }
    if (a.lastName.toUpperCase() > b.lastName.toUpperCase()) {
      return 1
    } else if (a.lastName.toUpperCase() < b.lastName.toUpperCase()) {
      return -1
    } else {
      if (a.firstName === null) {
        return 1
      }
      if (b.firstName === null) {
        return -1
      }
      if (a.firstName.toUpperCase() > b.firstName.toUpperCase()) {
        return 1
      } else if (a.firstName.toUpperCase() < b.firstName.toUpperCase()) {
        return -1
      } else {
        return 0
      }
    }
  }
  setRefetchGroupsByCompany(refetch)
  return (
    <ul>
      {users.sort(compare).map((u) => (
        <li key={u.id}>
          <Link path={`/team/user/${u.id}`}>
            <div className="user clickable">
              <i
                className={
                  u.isSuperUser
                    ? 'fa fa-star yellow'
                    : u.isManager
                    ? 'fa fa-star'
                    : u.canEdit
                    ? 'fa fa-star-half'
                    : 'fa fa-star-half-stroke'
                }
              />{' '}
              {formatName(u)}
            </div>
          </Link>
        </li>
      ))}
    </ul>
  )
}

// const AdminUsersList = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(({companyID, setCompany}) => (
//   <Query query={GET_COMPANIES}>
//     {({loading, error, data}) => {
//       if (loading) return <Loading />
//       if (error) return <div>error</div>

//       return (
//         <ul>
//           {data.companies.map((c) => {
//             const selected = c.id === companyID
//             return (
//               <li
//                 key={c.id}
//                 onClick={() => {
//                   setCompany(selected ? null : c.id)
//                   route('/team')
//                 }}
//                 className="company"
//               >
//                 <div
//                   className={
//                     selected
//                       ? 'company-name clickable active'
//                       : 'company-name clickable'
//                   }
//                 >
//                   {c.name}
//                 </div>
//                 {selected && <UsersList users={c.users} />}
//               </li>
//             )
//           })}
//         </ul>
//       )
//     }}
//   </Query>
// ))

const GroupsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    groupID,
    setGroup,
    company,
    isSuperUser,
    isSpecificUser,
    users,
    setRefetchGroupsByCompany
  }) => (
    <Query query={GET_GROUPS_BY_COMPANY} variables={{companyID: company.id}}>
      {({loading, error, data, refetch, networkStatus}) => {
        if (networkStatus === 4) return <Loading />
        if (loading) return <Loading />
        if (error) return <div>error</div>
        const compare = (a, b) => {
          if (a.name === null) {
            return 1
          }
          if (b.name === null) {
            return -1
          }
          if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return 1
          } else if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1
          } else {
            return 0
          }
        }
        return (
          <div>
            {(!isSuperUser && !isSpecificUser) && (
              <h3 className="group-heading">
                {company && `${company.name} Groups`}
              </h3>
            )}
            <ul>
              <li>
                {/* <Link path={company && `/team/company/${company.id}`}> */}
                <div
                  className={
                    groupID === 'companyWide'
                      ? 'group clickable active'
                      : 'group clickable'
                  }
                  onClick={() => {
                    setGroup('companyWide')
                    route(`/team/companyWide/${company.id}`)
                  }}
                >
                  {company && 'Company Wide'}
                </div>
                {/* </Link> */}
                {groupID === 'companyWide' && (
                  <UsersList
                    users={users}
                    refetch={refetch}
                    setRefetchGroupsByCompany={setRefetchGroupsByCompany}
                  />
                )}
              </li>
              {data.groups &&
                data.groups.sort(compare).map(
                  (g) =>
                    g.name && (
                      <li key={g.id}>
                        <div
                          className={
                            g.id === groupID
                              ? 'group clickable active'
                              : 'group clickable'
                          }
                          onClick={() => {
                            route(`/team/group/${g.id}`)
                            setGroup(g.id)
                          }}
                        >
                          {g.name}
                        </div>
                        {groupID === g.id && (
                          <UsersList
                            users={g.users}
                            refetch={refetch}
                            setRefetchGroupsByCompany={
                              setRefetchGroupsByCompany
                            }
                          />
                        )}
                      </li>
                    )
                )}
            </ul>
          </div>
        )
      }}
    </Query>
  )
)

const AdminGroupsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(({companyID, setCompany, setGroup, isSuperUser, isSpecificUser, viewInactiveCompanies}) => (
  <Query query={GET_COMPANIES}>
    {({loading, error, data}) => {
      if (loading) return <Loading />
      if (error) return <div>error</div>
      return (
        <ul>
          {data.companies
            .filter((c) => {
              if (data?.users?.id === 12034) {
                return [10344, 10303, 10359, 10337, 10345, 10379, 10292, 10381, 10365, 10228, 10384].includes(c.id)
              }
              if (data?.users?.id === 11394) {
                return [10344, 10345, 10379, 10337, 10381]
              }
              if (!!viewInactiveCompanies) {
                return true
              } else {
                return !!c.isActive
              }
            })
            .map((c) => {
              const selected = c.id === companyID
              return (
                <li key={c.id} className="company">
                  <div
                    onClick={() => {
                      setCompany(selected ? null : c.id)
                      setGroup(null)
                      route('/team')
                    }}
                    className={
                      selected
                        ? 'company-name clickable active'
                        : 'company-name clickable'
                    }
                  >
                    {c.name}
                  </div>
                  {selected && (
                    <GroupsList
                      company={c}
                      isSuperUser={isSuperUser}
                      isSpecificUser={isSpecificUser}
                      setGroup={setGroup}
                      users={c.users}
                    />
                  )}
                </li>
              )
            })}
        </ul>
      )
    }}
  </Query>
))
