import React from 'react'
import isNumber from 'lodash/isNumber'
import {dateTime as formatDateTime} from 'utils/format'
import getGpsCoordinates from 'utils/_getGpsCoordinates'
import Loading from 'Components/Loading'
import {Input, InputWrapper, InputTable} from 'Components/Form'
import Modal from 'Components/Modal'
//import GoogleMap from './Map'
import EVENTS from 'utils/eventCodes'
import {linkHandler, Link} from 'router'
import {graphql} from 'react-apollo'
import * as compose from 'lodash/flowRight'
import Loadable from 'react-loadable'
import {
  GET_DEVICE_RE,
  UPDATE_DEVICE,
  REPLACE_DEVICE,
  ADD_STATUS,
  ADD_LOG_COMMENT,
  GET_EVENTS
} from './queries'
import {Query} from 'react-apollo'
import TagCard from 'Components/TagCard'
import Graph from './Tags/Graph'
import TagClass from 'utils/Tag'
import {connect} from 'react-redux'
import {setDirectoryPath, setDirectoryCompany} from 'store/actions'
import {withToolbar} from 'Directory'
import DeviceSettings from './Settings'
import {CLEAR_DEVICE_DATA} from './queries'
import {gql} from 'graphql.macro'

import './index.scss'

const GoogleMap = new Loadable({
  loader: () => import('./Map'),
  loading: Loading
})

const GET_COMPANIES = gql`
  query GET_COMPANIES {
    companies {
      id
      name
    }
  }
`
const convertStringFloat = (str) => `${str.substring(0,str.length-2)}.${str.substring(str.length-2)}`
const displayFirmwareVersion = (version) => {
  const versionString = `${version}`
  return versionString.length === 5 ? parseFloat(convertStringFloat(versionString)) : version
}

class ViewDevice extends React.Component {
  state = {
    editing: false,
    modal: false,
    deviceLogComment: '',
    deviceLogStatus: null,
    replacementID: null,
    name: '',
    path: '',
    deviceType: null,
    companyID: null,
    isActive: true,
    inService: true,
    timeOffset: 0,
    accountingCode: null,
    apiNumber: null,
    userAssignedID: null,
    description: '',
    address: '',
    clearDataWarning: false,
    simNumber: null,
  }
  componentDidMount() {
    this.initializeState(this.props)
    const device = this.props.data.deviceRe
    if (device) {
      this.props.setDirectoryPath(device.path)
      this.props.setDirectoryCompany(device.company ? device.company.id : null)
    }
  }
  componentDidUpdate(prevProps) {
    // if the device data has been update, then reinitialize the component (unless currently editing)
    if (
      prevProps.data.deviceRe !== this.props.data.deviceRe &&
      (!this.state.editing ||
        !prevProps.data.deviceRe ||
        !this.props.data.deviceRe ||
        prevProps.data.deviceRe.id !== this.props.data.deviceRe.id)
    )
      this.componentDidMount()
  }
  initializeState = (props) => {
    if (props.data.loading || props.data.error || !props.data.deviceRe) return
    const device = props.data.deviceRe
    this.setState({
      editing: false,
      modal: false,
      deviceLogComment: '',
      deviceLogStatus: null,
      replacementID: null,
      name: device.name,
      path: device.path,
      deviceType: device.deviceType,
      companyID: device.company ? device.company.id : null,
      isActive: device.isActive,
      inService: device.inService,
      timeOffset: device.timeOffset,
      accountingCode: device.accountingCode,
      apiNumber: device.apiNumber === null ? null : parseInt(device.apiNumber),
      userAssignedID: device.userAssignedID,
      description: device.description,
      address: device.address,
      simNumber: device.simNumber,
      loading: false
    })
  }
  onChange = (e) => {
    const key = e.target.dataset && e.target.dataset.key
    let value = e.target.value
    if (key === 'apiNumber' && isNaN(value)) {
      value = null
    }
    if (!this.state.hasOwnProperty(key)) return
    this.setState({[key]: value})
  }
  updateDevice = () => {
    this.props.updateDevice({
      variables: {
        id: this.props.deviceID,
        update: {
          name: this.state.name,
          path: this.state.path,
          deviceType: this.state.deviceType,
          companyID: this.state.companyID,
          isActive: this.state.isActive,
          inService: this.state.inService,
          timeOffset: this.state.timeOffset,
          accountingCode: this.state.accountingCode,
          apiNumber:
            typeof this.state.apiNumber === 'number'
              ? this.state.apiNumber.toString()
              : null,
          userAssignedID:
            this.state.userAssignedID === '' ? null : this.state.userAssignedID,
          description: this.state.description,
          address: this.state.address
        }
      }
    })
    this.setState({editing: false})
  }

  clearDeviceData = async () => {
    this.setState({
      editing: false,
      clearDataWarning: false,
      loading: true
    })

    this.updateDevice()

    await this.props.clearDeviceData({
      variables: {deviceID: this.props.deviceID}
    })
    // this.setState({loading: false})
    window.location.reload()
  }
  renderTree = (tag, subtype1, subtype2) => {
    let tagString = tag
    let subString1 = subtype1
    let subString2 = subtype2
    switch (tag) {
      case 'spsr':
        tagString = 'Tank Level'
        subString1 = subtype1
        switch (subtype2) {
          case 1:
            subString2 = 'Alphawire White'
            break
          case 2:
            subString2 = 'Shenyuan 5.5mm'
            break
          default:
            subString2 = 'Not Reported'
        }
        break
      case 'dspsr':
        tagString = 'Tank Level - Dual Sensor'
        subString1 = subtype1
        switch (subtype2) {
          case 1:
            subString2 = 'Alphawire White'
            break
          case 2:
            subString2 = 'Shenyuan 5.5mm'
            break
          default:
            subString2 = 'Not Reported'
        }
        break
      case 'psr':
        tagString = 'Pressure'
        subString1 = subtype1
        switch (subtype2) {
          case 1:
            subString2 = 'Normal'
            break
          case 2:
            subString2 = 'SS Mesh Covering'
            break
          default:
            subString2 = 'Not Reported'
        }
        break
      case 'puls':
        tagString = 'Turbine'
        subString1 = 'na'
        subString2 = 'na'
        break
      case 'rot':
        tagString = 'Beam Pump'
        switch (subtype1) {
          case 1:
            subString1 = 'Dongle - Internal'
            break
          case 2:
            subString1 = 'Dongle - Sensor'
            break
          default:
            subString1 = 'Dongle - Not Reported'
        }
        subString2 = 'na'
        break
      case 'temp':
        tagString = 'Temperature'
        switch (subtype1) {
          case 1:
            subString1 = 'Skin'
            break
          case 2:
            subString1 = 'Insert'
            break
          default:
            subString1 = 'Not Reported'
        }
        switch (subtype2) {
          case 1:
            subString2 = 'Bare Wire'
            break
          case 2:
            subString2 = 'SS Mesh Covering'
            break
          default:
            subString2 = 'Not Reported'
        }
        break
      case 'vib':
        tagString = 'Vibration'
        switch (subtype1) {
          case 1:
            subString1 = 'Dongle - Internal'
            break
          case 2:
            subString1 = 'Dongle - Sensor'
            break
          case 3:
            subString1 = 'External - Cable'
            break
          default:
            subString1 = 'Not Reported'
        }
        subString2 = 'na'
        break
      case 'dist':
        tagString = 'Radar'
        switch (subtype1) {
          case 1:
            subString1 = 'Internal'
            break
          case 2:
            subString1 = 'External - Cable'
            break
          default:
            subString1 = 'Not Reported'
        }
        subString2 = 'na'
        break
      case 'hum':
        tagString = 'Humidity'
        subString1 = 'na'
        subString2 = 'na'
        break
      case 'sonr':
        tagString = 'Sonar'
        subString1 = 'na'
        subString2 = 'na'
        break
      default:
        tagString = 'na'
        subString1 = 'na'
        subString2 = 'na'
        break
    }
    return `${tagString}/${subString1}/${subString2}`
  }

  render() {
    if (this.props.data.loading) return <Loading />
    if (this.state.loading) return <Loading />
    if (this.props.data.error) return 'Device cannot be accessed'
    if (!this.props.data.deviceRe) return 'Device not found.'

    const {deviceGps, deviceRe, me} = this.props.data
    // for consistency purposes
    const device = deviceRe
    const isSuperUser = me.isSuperUser
    const isManager = me.isManager
    const isSpecificUser = this.props.data.me.id === 12034 || this.props.data.me.id === 11394
    const isNicoMabilleauAndValidDevice = this.props.userId === 11477 && [5890, 5982].includes(this.props.deviceID)
    const VoltageTag = new TagClass(device.voltage)
    const tags = device.tags || []
    const primaryTags = tags.filter((tag) => tag.tag.slice(0, 1) !== '_')
    const secondaryTags = tags.filter((tag) => tag.tag.slice(0, 1) === '_')
    const missingPath = !device.path || device.path === ''
    const missingName = !device.name || device.name === ''

    const {latitude, longitude} = getGpsCoordinates(
      {latitude: device.latitude, longitude: device.longitude},
      {latitude: deviceGps.latitude, longitude: deviceGps.longitude}
    )

    return (
      <div className="view-device">
        <div className="device-name">
          <h1 className="name">{device.name}</h1>
        </div>
        <div className="body">
          {missingPath && missingName && (
            <div className="row">
              <div className="col new-device-message">
                This is a new device. To place this device in your directory,
                click the <strong>Edit Device</strong> button and give the
                device a <strong> name</strong> and a
                <strong> directory path</strong>.
              </div>
            </div>
          )}
          {missingPath && !missingName && (
            <div className="row">
              <div className="col new-device-message">
                This is a new device. To place this device in your directory,
                click the <strong>Edit Device</strong> button and give the
                device a<strong> directory path</strong>.
              </div>
            </div>
          )}
          {!missingPath && missingName && (
            <div className="row">
              <div className="col new-device-message">
                This is a new device. To place this device in your directory,
                click the <strong>Edit Device</strong> button and give the
                device a <strong> name</strong>.
              </div>
            </div>
          )}
          <div className="grid">
            <div className="box info">
              <div className="title">
                <span className="left">Device Info</span>
                <span className="right">
                  {!me.isDemo && !this.state.editing && (
                    <button onClick={() => this.setState({editing: true})}>
                      Edit Device
                    </button>
                  )}
                  {!me.isDemo && this.state.editing && (
                    <div className="save-buttons">
                      <button onClick={() => this.initializeState(this.props)}>
                        Cancel
                      </button>{' '}
                      <button className="primary" onClick={this.updateDevice}>
                        Save Changes
                      </button>
                    </div>
                  )}
                </span>
              </div>
              <div className="body">
                <InputTable>
                  <InputWrapper label="Device ID">{device.id}</InputWrapper>
                  <Input
                    id="device-name-input"
                    type="string"
                    label="Name"
                    onChange={this.onChange}
                    data-key="name"
                    value={this.state.name}
                    static={!this.state.editing}
                  />
                  {me.canEdit && (
                    <Input
                      type="string"
                      label="Accounting Code"
                      tooltip="An user defined label for accounting purposes. This code will be available on your monthly invoice."
                      onChange={this.onChange}
                      data-key="accountingCode"
                      value={this.state.accountingCode}
                      static={!this.state.editing}
                    />
                  )}
                  <Input
                    id="device-path-input"
                    type="directory"
                    label="Directory Path"
                    onChange={this.onChange}
                    data-key="path"
                    value={this.state.path}
                    static={!this.state.editing}
                  />
                  {me.canEdit && (
                    <InputWrapper label="Coordinates" className="coordinates">
                      {isNumber(longitude) &&
                      isNumber(latitude) ? (
                        <span
                          className={'link'}
                          onClick={() => this.setState({modal: 'showMap'})}
                        >
                          {`${latitude.toFixed(
                            7
                          )}, ${longitude.toFixed(7)}`}
                        </span>
                      ) : !me.canEdit ? (
                        <em>None</em>
                      ) : (
                        <span
                          className="link"
                          onClick={() => this.setState({modal: 'showMap'})}
                        >
                          <i className="fa fa-plus" />
                          Add Location
                        </span>
                      )}
                    </InputWrapper>
                  )}
                  {me.canEdit && (
                    <Input
                    type="string"
                    label="Address"
                    tooltip="User can assign an address for documentation purposes only (not used by any features)."
                    onChange={this.onChange}
                    data-key="address"
                    value={this.state.address}
                    static={!this.state.editing}
                    />
                  )}
                  {me.canEdit && (
                    <Input
                      type="number"
                      label="Well API Number"
                      tooltip="American Petroleum Institute unique identifier - 10 to 12 digits."
                      onChange={this.onChange}
                      data-key="apiNumber"
                      value={
                        this.state.apiNumber === null ? (
                          <em>None</em>
                        ) : (
                          this.state.apiNumber
                        )
                      }
                      static={!this.state.editing}
                    />
                  )}
                  {me.canEdit && (
                    <Input
                      type="string"
                      label="User Assigned ID"
                      tooltip="Users can assign a device a unique identifier for use in external data management systems."
                      onChange={this.onChange}
                      data-key="userAssignedID"
                      value={this.state.userAssignedID}
                      static={!this.state.editing}
                    />
                  )}
                  {me.canEdit && (
                    <Input
                      type="string"
                      label="Description"
                      onChange={this.onChange}
                      data-key="description"
                      value={this.state.description}
                      static={!this.state.editing}
                    />
                  )}
                  {me.canEdit && (
                    <Input
                      type="checkbox"
                      label="Active"
                      tooltip={`Inactive devices are normally hidden and don't show alarms or health warnings.`}
                      onChange={() =>
                        this.setState({isActive: !this.state.isActive})
                      }
                      data-key="isActive"
                      value={this.state.isActive}
                      static={!this.state.editing}
                    />
                  )}
    
                  {(!isSuperUser && isSpecificUser) && (
                    <>
                    <Query query={GET_COMPANIES}>
                        {({loading, error, data}) => (
                          <Input
                            type="select"
                            label="Company"
                            onChange={this.onChange}
                            data-key="companyID"
                            value={this.state.companyID}
                            static={!this.state.editing}
                            options={[{label: 'None', value: null}].concat(
                              ((data && data.companies) || []).filter((c) => {
                                return [10344, 10303, 10359, 10337, 10345, 10379, 10292, 10381, 10365, 10228, 10384].includes(c.id)
                              })
                              .map((c) => ({
                                label: c.name,
                                value: c.id
                              }))
                            )}
                          />
                        )}
                      </Query>
                    </>
                  )}
                  {isSuperUser && (
                    <>
                      <Input
                        type="checkbox"
                        label="In Service"
                        tooltip={`Devices that are not in service are not shown in the directory, and are removed from billing.`}
                        onChange={() =>
                          this.setState({inService: !this.state.inService})
                        }
                        data-key="inService"
                        value={this.state.inService}
                        static={!this.state.editing}
                      />
                      <Query query={GET_COMPANIES}>
                        {({loading, error, data}) => (
                          <Input
                            type="select"
                            label="Company"
                            onChange={this.onChange}
                            data-key="companyID"
                            value={this.state.companyID}
                            static={!this.state.editing}
                            options={[{label: 'None', value: null}].concat(
                              ((data && data.companies) || []).map((c) => ({
                                label: c.name,
                                value: c.id
                              }))
                            )}
                          />
                        )}
                      </Query>
                      <InputWrapper label="Device Install PIN">
                        {device.pin || <em>Not Available</em>}
                      </InputWrapper>
                      <Input
                        type="select"
                        label="Timezone Correction"
                        onChange={this.onChange}
                        data-key="timeOffset"
                        value={this.state.timeOffset}
                        static={!this.state.editing}
                        options={[
                          {label: 'None (newer hardware)', value: 0},
                          {label: 'CST (old hardware)', value: 18000}
                        ]}
                      />
                      <InputWrapper label="Board Rev / Firmware Version">
                        {`${device.boardRev || 'None'} / ${
                          displayFirmwareVersion(device.firmwareVersion) || 'None'
                        }`}
                      </InputWrapper>
                      <InputWrapper label="Modem Type">
                        {device.modemType || 'None'}
                      </InputWrapper>
                      <InputWrapper label="Sensor Type Tree">
                        {this.renderTree(
                          device.defaultTag,
                          device.sensorSubtype1,
                          device.sensorSubtype2
                        )}
                      </InputWrapper>
                      <InputWrapper label="Replacement Device ID">
                        {device.replacementID ? (
                          <span
                            className="link"
                            onClick={linkHandler(
                              `/devices/${device.replacementID}`
                            )}
                          >
                            {device.replacementID}
                          </span>
                        ) : (
                          <span
                            className="link"
                            onClick={() =>
                              this.setState({modal: 'replaceDevice'})
                            }
                          >
                            <em>None</em>
                          </span>
                        )}
                      </InputWrapper>
                      <InputWrapper label="Model">
                        {device.model || <em>None</em>}
                      </InputWrapper>
                      {isSuperUser && (
                        <InputWrapper label="SIM Number">
                          {device.simNumber || <em>None</em>}
                        </InputWrapper>
                      )}
                      {device.manufacturerCode ? (
                        <InputWrapper label="Manufacturer">
                          {(device.manufacturerCode || '').toUpperCase()}{' '}
                          {device.po ? 'P.O ' + device.po : ''}
                        </InputWrapper>
                      ) : null}
                      {this.state.editing && (
                        <InputWrapper label="Ready to Ship?">
                          <button
                            className="red primary"
                            onClick={() =>
                              this.setState({clearDataWarning: true})
                            }
                            style={{margin: '5px'}}
                          >
                            Clear Data
                          </button>{' '}
                        </InputWrapper>
                      )}
                      {this.state.clearDataWarning && (
                        <Modal
                          onClose={() =>
                            this.setState({clearDataWarning: false})
                          }
                        >
                          <Modal.Title>Confirm Before Continuing</Modal.Title>
                          <Modal.Body>
                            Are you sure that you want to clear all tag data for
                            this device?
                          </Modal.Body>
                          <Modal.Footer>
                            <button
                              onClick={() =>
                                this.setState({clearDataWarning: false})
                              }
                            >
                              Cancel
                            </button>
                            <button
                              onClick={this.clearDeviceData}
                              className="red primary"
                            >
                              Yes, Clear All Data for <b>device ID: {this.props.deviceID}</b>
                            </button>
                          </Modal.Footer>
                        </Modal>
                      )}{' '}
                    </>
                  )}
                  {me.isDemo && !isSuperUser && (
                    <>
                      <Input
                        type="select"
                        label="Company"
                        data-key="companyID"
                        value={this.state.companyID}
                        static={true}
                        options={[{label: 'None', value: null}].concat([
                          {
                            label: 'DEMO',
                            value: 0
                          }
                        ])}
                      />
                      <InputWrapper label="Device Install PIN">
                        {device.pin || <em>Not Available</em>}
                      </InputWrapper>
                      <Input
                        type="string"
                        label="Description"
                        tooltip="Visible to admins only."
                        data-key="description"
                        value={this.state.description}
                        static={true}
                      />
                      <Input
                        type="select"
                        label="Timezone Correction"
                        data-key="timeOffset"
                        value={this.state.timeOffset}
                        static={true}
                        options={[
                          {label: 'None (newer hardware)', value: 0},
                          {label: 'CST (old hardware)', value: 18000}
                        ]}
                      />
                      <InputWrapper label="Board Rev / Firmware Version">
                        {`${device.boardRev || 'None'} / ${
                          displayFirmwareVersion(device.firmwareVersion) || 'None'
                        }`}
                      </InputWrapper>
                      <InputWrapper label="Modem Type">
                        {device.modemType || 'None'}
                      </InputWrapper>
                      <InputWrapper label="Replacement Device ID">
                        {
                          <span>
                            <em>None</em>
                          </span>
                        }
                      </InputWrapper>
                      <InputWrapper label="Model">
                        {device.model || <em>None</em>}
                      </InputWrapper>
                      <InputWrapper label="Manufacturer">
                        {(device.manufacturerCode || '').toUpperCase()}{' '}
                        {device.po ? 'P.O ' + device.po : ''}
                      </InputWrapper>
                    </>
                  )}
                </InputTable>
              </div>
            </div>
            {/* display 2-way settings UI if the user is a Super User, a Manager, and if the firmware version can handle 2-way -Eli, 2/19/20  */}
            {(isSuperUser || isManager || isNicoMabilleauAndValidDevice) && (
              (device.firmwareVersion >= 167 && device.firmwareVersion <= 199) ||
              `${device.firmwareVersion}`.length === 5)
              ? (
              <DeviceSettings
                deviceID={device.id}
                defaultTag={device.defaultTag}
                settings={device.settings}
                isManager={isManager}
                isSuperUser={isSuperUser}
                userId={this.props.data.me.id}
                firmwareVersion={device.firmwareVersion}
              />
            ) : null}
            <div className="box data-streams">
              <div className="title">
                <span>Data Streams</span>
              </div>
              <div className="body tag-cards">
                {primaryTags
                  .filter((t) => t.tag !== '_del')
                  .sort((a, b) => (a.time > b.time ? -1 : 1))
                  .map((t) => (
                    <TagCard key={t.tag} device={device} tag={t} horizontal label />
                  ))}
                {secondaryTags
                  .filter((t) => t.tag !== '_del')
                  .sort((a, b) => (a.time > b.time ? -1 : 1))
                  .map((t) => (
                    <TagCard key={t.tag} device={device} tag={t} horizontal label />
                  ))}
              </div>
            </div>
            <div className="box events">
              <div className="title">
                <span className="left">Events</span>
                {isSuperUser && (
                  <span className="right-event-box">
                    <button onClick={() => this.setState({modal: 'showMoreEvents'})} className="events-expand-button">
                      All Events
                    </button>
                    <Link path={`/admin/messageLog/${device.id}`}>
                      View Message Log
                    </Link>
                  </span>
                )}
              </div>
              <div className="body">
                <table>
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Event</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!device.events &&
                      device.events
                        .concat()
                        // sometimes we get bunk event codes sent from the firmware, filter those out
                        .filter((e) => EVENTS[e.flag])
                        // if they aren't a superuser, only show the startup and powered off messages
                        .filter((e) => !isSuperUser ? [1, 2, 3].includes(e.flag) : e)
                        .map((e, i) => (
                          <tr key={i}>
                            <td>{formatDateTime(e.time)}</td>
                            <td>
                              <span className={'event-label event-' + e.flag}>
                                {EVENTS[e.flag]}
                              </span>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="box voltage-graph">
              <div className="title">Battery</div>
              <div className="body">
                <Graph me={me} device={device} Tag={VoltageTag} diff={true}/>
              </div>
            </div>
            {this.state.modal === 'showMap' && (
              <GoogleMap
                latitude={latitude}
                longitude={longitude}
                canEdit={me.canEdit}
                onClose={() => this.setState({modal: false})}
                updateLocation={({latitude, longitude}) =>
                  this.props.updateDevice({
                    variables: {
                      id: device.id,
                      update: {latitude, longitude}
                    }
                  })
                }
              />
            )}
            {this.state.modal === 'replaceDevice' && (
              <Modal onClose={() => this.setState({modal: false})}>
                <Modal.Title>Replace Device</Modal.Title>
                <Modal.Body>
                  <p>
                    Replacing this device will copy over the device settings to
                    the new device. The data from this old device will be
                    combined with data from the new device.
                  </p>
                  <table>
                    <thead>
                      <tr>
                        <td />
                        <td>Old Device</td>
                        <td>New Device</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ID</td>
                        <td>{device.id}</td>
                        <td>
                          <Input
                            label="New Device ID"
                            type="number"
                            value={this.state.replacementID}
                            nullable
                            onChange={(e) =>
                              this.setState({replacementID: e.target.value})
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Active</td>
                        <td>No</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Name</td>
                        <td>{device.name}</td>
                        <td>{device.name}</td>
                      </tr>
                      <tr>
                        <td>Path</td>
                        <td>{device.path}</td>
                        <td>{device.path}</td>
                      </tr>
                      <tr>
                        <td>Company</td>
                        <td>{device.company && device.company.name}</td>
                        <td>{device.company && device.company.name}</td>
                      </tr>
                      <tr>
                        <td>Calibrations</td>
                        <td>transfer {'->'}</td>
                      </tr>
                      <tr>
                        <td>Alarms</td>
                        <td>transfer {'->'}</td>
                      </tr>
                      <tr>
                        <td>Graph Settings</td>
                        <td>transfer {'->'}</td>
                      </tr>
                    </tbody>
                  </table>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    onClick={() =>
                      this.setState({modal: false, replacementID: null})
                    }
                  >
                    Cancel
                  </button>
                  <button
                    className="primary"
                    onClick={() => {
                      this.props.replaceDevice({
                        variables: {
                          oldDeviceID: device.id,
                          newDeviceID: this.state.replacementID
                        }
                      })
                      this.setState({modal: false})
                    }}
                  >
                    Replace this Device
                  </button>
                </Modal.Footer>
              </Modal>
            )}
            {this.state.modal === 'showMoreEvents' && (
              <Query query={GET_EVENTS} variables={{id: this.props.deviceID}}>
              {({error, loading, data}) => {
                if (error) return 'error'
                if (loading) return <Loading />

                return (
                  <Modal onClose={() => this.setState({modal: false})}>
                  <div className='more-events-table'>
                    <div className='box events'>
                    <table>
                      <thead>
                        <tr>
                          <th>Time</th>
                          <th>Event</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.listEvents
                            .concat()
                            // sometimes we get bunk event codes sent from the firmware, filter those out
                            .filter((e) => EVENTS[e.flag])
                            // if they aren't a superuser, only show the startup and powered off messages
                            .filter((e) => !isSuperUser ? [1, 2, 3].includes(e.flag) : e)
                            .map((e, i) => (
                              <tr key={i}>
                                <td>{formatDateTime(e.time)}</td>
                                <td>
                                  <span className={'event-label event-' + e.flag}>
                                    {EVENTS[e.flag]}
                                  </span>
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                    </div>
                  </div>
                  <Modal.Footer>
                    <button onClick={() => this.setState({modal: false})}>
                        Close
                    </button>
                  </Modal.Footer>
                </Modal>   
                )
              }}
              </Query>           
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = () => ({})
const mapDispatchToProps = (dispatch) => ({
  setDirectoryPath: (path) => dispatch(setDirectoryPath(path)),
  setDirectoryCompany: (id) => dispatch(setDirectoryCompany(id))
})
export default compose(
  graphql(GET_DEVICE_RE, {
    options: (props) => ({
      variables: {id: props.deviceID},
      fetchPolicy: 'no-cache' // for some reason the cache gets corrupted or something on this call specifically
    })
  }),
  graphql(UPDATE_DEVICE, {name: 'updateDevice'}),
  graphql(REPLACE_DEVICE, {name: 'replaceDevice'}),
  graphql(ADD_STATUS, {name: 'addStatus'}),
  graphql(ADD_LOG_COMMENT, {name: 'addLogComment'}),
  graphql(CLEAR_DEVICE_DATA, {name: 'clearDeviceData'}),
  connect(mapStateToProps, mapDispatchToProps),
  withToolbar
)(ViewDevice)
