import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import * as compose from 'lodash/flowRight'
import {gql} from 'graphql.macro'
import Loading from 'Components/Loading'
import TagClass from 'utils/Tag'
import AdditionalTagClass from 'utils/AdditionalTag'
import {Link} from 'router'
import {connect} from 'react-redux'
import {setDirectoryPath} from 'store/actions'
import {withToolbar} from 'Directory'
// import Modal from 'Components/Modal'
import Graph from './Graph'
import Table from './Table'
import Alarms from './Alarms'
import Settings from './Settings'
import ProductionReportLevel from './ProductionReportLevel'
import ProductionReportTurbine from './ProductionReportTurbine'
import isMobile from 'utils/isMobile'
import {GET_TAG} from './queries'
// import {route} from 'router'

import './index.scss'
import ProductionReportFlow from './ProductionReportFlow'

const twoWeeksInMilliseconds = 1209600000
const oneWeekInMilliseconds = twoWeeksInMilliseconds / 2
const GET_DATA_MAX_TIME = () => Date.now() / 1000 + 15 * 60
const GET_START_TIME = () => GET_DATA_MAX_TIME() - (isMobile() ? oneWeekInMilliseconds / 1000 : twoWeeksInMilliseconds / 1000)

const ACK_ALARM = gql`
  mutation ACK_ALARM($deviceID: Int!, $tag: String!) {
    ackAlarm(deviceID: $deviceID, tag: $tag) {
      device {
        id
        tags {
          id
          alarms {
            flag
          }
        }
      }
    }
  }
`

// const UPDATE_SENSOR = gql`
//   mutation UPDATE_SENSOR($deviceID: Int!, $change: String!) {
//     updateSensor(deviceID: $deviceID, change: $change) {
//       device {
//         id
//       }
//     }
//   }
// `

const tabs = [
  {name: 'Graph', key: 'graph', component: Graph},
  {name: 'Table', key: 'table', component: Table},
  {name: 'Alarms', key: 'alarms', component: Alarms},
  {name: 'Settings', key: 'settings', component: Settings}
]

// Change the name of production report to consumption report for EXXON tank sensors - EXXON REQUESTED CHANGE
const consumptionReportLevelTab = {
  name: 'Consumption Report',
  key: 'levelReport',
  component: ProductionReportLevel
}
const productionReportLevelTab = {
  name: 'Production Report',
  key: 'levelReport',
  component: ProductionReportLevel
}
const productionReportTurbineTab = {
  name: 'Production Report',
  key: 'turbineReport',
  component: ProductionReportTurbine
}
const productionReportFlowTab = {
  name: 'Production Report',
  key: 'flowReport',
  component: ProductionReportFlow
}

const alarmMessages = {
  low: `The sensor triggered a low alarm.`,
  high: `The sensor triggered a high† alarm.`,
  low_warning: `The sensor triggered a low warning alarm.`,
  high_warning: `The sensor triggered a high† warning alarm.`,
  critical_low: `The sensor triggered a critical low alarm.`,
  critical_high: `The sensor triggered a critical high† alarm.`,
  norm: `The sensor's reading returned to normal.`,
  test: `This is a test of the sensor's alarm system.`
}

class Tags extends Component {
  state = {tab: tabs[0].key, confirming: false, isVisible: true}

  componentDidMount() {
    if (this.props.data.deviceReGraph) {
      this.props.setDirectoryPath(this.props.data.deviceReGraph.path)
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.data.deviceReGraph && !this.props.data.loading && !this.props.data.variables.downsampled) {

      this.props.data.refetch({
        deviceID: this.props.deviceID, tag: this.props.tag, startTime: GET_START_TIME(), endTime: GET_DATA_MAX_TIME(), downsampled: true
      })
    }

    if (prevProps.data.deviceReGraph !== this.props.data.deviceReGraph && this.props.data.deviceReGraph) {
      this.props.setDirectoryPath(this.props.data.deviceReGraph.path)
    }
  }

  render() {
    if (this.props.data.loading && !this.props.data.variables.downsampled) return <Loading />
    if (this.props.data.error) return 'Error'

    const Tag =
      this.props.data.deviceReGraph &&
      this.props.data.deviceReGraph.tag &&
      TagClass(this.props.data.deviceReGraph.tag)

    const Tag2 =
      this.props.data.deviceReGraph &&
      this.props.data.deviceReGraph.tag &&
      this.props.data.deviceReGraph.tag.secondaryGraph &&
      TagClass(this.props.data.deviceReGraph.tag.secondaryGraph)
    const Tag3 =
      this.props.data.deviceReGraph &&
      this.props.data.deviceReGraph.tag &&
      this.props.data.deviceReGraph.tag.thirdGraph &&
      TagClass(this.props.data.deviceReGraph.tag.thirdGraph)
    const Tag4 =
      this.props.data.deviceReGraph &&
      this.props.data.deviceReGraph.tag &&
      this.props.data.deviceReGraph.tag.fourthGraph &&
      TagClass(this.props.data.deviceReGraph.tag.fourthGraph)
    const Tag5 =
      this.props.data.deviceReGraph &&
      this.props.data.deviceReGraph.tag &&
      this.props.data.deviceReGraph.tag.fifthGraph &&
      TagClass(this.props.data.deviceReGraph.tag.fifthGraph)

    let additionalTags = []
    if (Tag.additionalTagData.length > 0) {
      Tag.additionalTagData.forEach((dataNode) => {
        additionalTags.push(AdditionalTagClass(dataNode))
      })
    }

    const sf6Tag =
      this.props.data.deviceReGraph &&
      this.props.data.deviceReGraph.tag &&
      this.props.data.deviceReGraph.tag.sf6NormalizedData &&
      TagClass({
        ...this.props.data.deviceReGraph.tag,
        data: this.props.data.deviceReGraph.tag.sf6NormalizedData
      })

    if (!Tag) return 'Unable to load data.'

    const {deviceReGraph, me} = this.props.data

    const device = deviceReGraph

    const Content = tabs
      .concat(productionReportLevelTab)
      .concat(productionReportTurbineTab)
      .concat(productionReportFlowTab)
      .find((t) => t.key === this.state.tab).component
    
    const childProps = {
      Tag,
      Tag2,
      Tag3,
      Tag4,
      Tag5,
      additionalTags,
      sf6Tag,
      device,
      me
    }

    return (
      <div className="tag-display">
        <div className="tag-display-header">
          <h1>
            <Link path={`/devices/${device.id}`}>{device.name}</Link>
          </h1>
          <i className="fa fa-angle-right" />
          <span className="tag-label">{Tag.label}</span>
          <span className="edit-device-link">
            <div className="device-id">ID: {device.id}</div>
            <Link path={`/devices/${device.id}`}>Device Info</Link>
          </span>
        </div>
        {Tag?.alarms?.rules.filter((i) => i.sent !== 0 && i.sent !== null).length !== 0 && (
        <div> 
          <div className={'open-alarm high'}>
            <span className="text">{`The sensor triggered an alarm rule. The rule is suppressed until readings return to normal. View the alarm tab for more detail.`}</span>
          </div>
        </div>
        )}
        {Tag.alarms.flag && (
          <div className={'open-alarm ' + Tag.alarms.flag}>
            <span className="text">{alarmMessages[Tag.alarms.flag]}</span>
            {this.state.confirming ? (
              <span className="buttons">
                <button onClick={() => this.setState({confirming: false})}>
                  Cancel
                </button>
                <button
                  className="primary"
                  onClick={() => {
                    this.props.ackAlarm()
                    this.setState({confirming: false})
                  }}
                >
                  Confirm
                </button>
              </span>
            ) : (
              <span className="buttons">
                <button
                  className="primary"
                  onClick={() => this.setState({confirming: true})}
                >
                  Acknowledge
                </button>
              </span>
            )}
          </div>
        )}
        {Tag.tag === 'spsr' && Tag.calibrations.length === 0 ? (
           <div className={'open-alarm cal'}>
           <span className="text">This sensor has not been calibrated and will not give reliable data.
            To calibrate the sensor, click on a point on the graph and choose
            the "Add Calibration" option.</span>
           </div>
        ) : null}
        {Tag.tag === 'dspsr' && Tag.calibrations.length === 0 ? (
           <div className={'open-alarm cal'}>
           <span className="text">This sensor has not been calibrated and will not give reliable data.
            To calibrate the sensor, click on a point on the graph and choose
            the "Add Calibration" option.</span>
           </div>
        ) : null}
        <div className="tabs">
          {( device.tag.calibration.tankName != null && (Tag.tag === 'spsr' || Tag.tag === 'dspsr')
            ? tabs.concat(consumptionReportLevelTab)
            : Tag.tag === 'spsr' || Tag.tag === 'dspsr'
            ? tabs.concat(productionReportLevelTab)
            : Tag.tag === 'puls'
            ? tabs.concat(productionReportTurbineTab)
            : Tag.tag === 'flw'
            ? tabs.concat(productionReportFlowTab)
            : tabs
          ).map((t) => (
            <button
              key={t.key}
              className={this.state.tab === t.key ? 'tab active' : 'tab'}
              onClick={() => this.setState({tab: t.key})}
            >
              {t.name}
            </button>
          ))}
        </div>
        <div className="content">
          <Content {...childProps} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = () => ({})
const mapDispatchToProps = (dispatch) => ({
  setDirectoryPath: (path) => dispatch(setDirectoryPath(path))
})

export default compose(
  graphql(GET_TAG, {
    options: (props) => ({
      variables: {deviceID: props.deviceID, tag: props.tag, startTime: GET_START_TIME(), endTime: GET_DATA_MAX_TIME()}
    })
  }),
  graphql(ACK_ALARM, {
    name: 'ackAlarm',
    options: (props) => ({
      variables: {deviceID: props.deviceID, tag: props.tag}
    })
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withToolbar
)(Tags)