import React from 'react'
import tagDataDecompress from 'utils/tagDataDecompress'
import fourierDataDecompress from 'utils/fourierDataDecompress'
import isNumber from 'lodash/isNumber'
import findClosestPoint from 'utils/findClosestPoint'
import {Input, InputRow, InputWrapper} from 'Components/Form'
import memoize from 'lodash/memoize'
import {dateTime as formatDateTime} from 'utils/format'
import getDisplayOptions from './getDisplayOptions'

export default memoize((tag) => new Tag(tag))

class Tag {
  constructor(tagData) {
    if (!tagData) return null
    this.tag = tagData.tag
    this.deviceID = tagData.deviceID
    this.time = tagData.time
    this.value = tagData.value
    this.ymin = tagData.ymin || this.display.ymin
    this.ymax = tagData.ymax || this.display.ymax
    this.alarms = tagData.alarms || {
      lowWarning: null,
      highWarning: null,
      criticalLow: null,
      criticalHigh: null,
      flag: null,
      trigger: null
    }
    this.displayUnits = tagData.displayUnits
    this.secondaryGraph = tagData.secondaryGraph
    this.thirdGraph = tagData.thirdGraph
    this.fourthGraph = tagData.fourthGraph
    this.fifthGraph = tagData.fifthGraph
    this.calibration = tagData.calibration
    this.calibrations = tagData.calibrations
    this.label = labels[this.tag] || labels.default
    this.userData = tagData.userData
    //cap battery voltage values at 4.3v
    // if (this.tag === '_v') {
    //   this.data = tagDataDecompress(tagData.data).map((row) => {
    //     if (row[1] > 4.3) {
    //       return [row[0], 4.3]
    //     } else {
    //       return row
    //     }
    //   })
    // }
    // else if (!!tagData.filterEnabled) {
    //   let newDataArray = []
    //   let filteredIndexes = []
    //   let inputData = tagDataDecompress(tagData.data)
    //   inputData.forEach((row, i) => {
    //     if (
    //       !!inputData[i - 1] &&
    //       !filteredIndexes.includes(i - 1) &&
    //       !!tagData.filterThreshold &&
    //       (row[1] >= inputData[i - 1][1] + tagData.filterThreshold ||
    //         row[1] <= inputData[i - 1][1] - tagData.filterThreshold)
    //     ) {
    //       filteredIndexes.push(i)
    //     } else if (
    //       (!!tagData.filterMinimumValue ||
    //         tagData.filterMinimumValue === 0) &&
    //       row[1] <= 0 - tagData.filterMinimumValue
    //     ) {
    //       filteredIndexes.push(i)
    //     } else {
    //       newDataArray.push(row)
    //     }
    //   })
    //   this.data = newDataArray
    //   this.filteredIndexes = filteredIndexes
    // }
    // else {
    //   this.data = tagDataDecompress(tagData.data)
    // }
    this.data = tagDataDecompress(tagData.data)
    this.render = this.display.render
    this.Calibration = Calibrations[this.tag] || Calibrations.default
    this.barrelsPerInch = tagData.barrelsPerInch
    this.reportHour = tagData.reportHour
    this.additionalTagData = tagData.additionalTagData
    this.filterEnabled = tagData.filterEnabled
    this.filterThreshold = tagData.filterThreshold
    this.filterMinimumValue = tagData.filterMinimumValue
    this.sensorTempFilterEnabled = tagData.sensorTempFilterEnabled
    this.sensorTempFilterThreshold = tagData.sensorTempFilterThreshold
    this.temperatureCorrectionEnabled = tagData.temperatureCorrectionEnabled
    this.temperatureCorrectionCoefficient =
      tagData.temperatureCorrectionCoefficient
    this.temperatureCorrectionThreshold = tagData.temperatureCorrectionThreshold
    this.sf6NormalizationEnabled = tagData.sf6NormalizationEnabled
    this.averageTemperature = tagData.averageTempData
    if (tagData.tag === 'vib') {
      this.fourierData = fourierDataDecompress(tagData.fourierData)
    }
    this.fourierPlotEnabled = tagData.fourierPlotEnabled
    this.maximumFourierAmplitude = tagData.maximumFourierAmplitude
    this.activeAdditional = tagData.activeAdditional
  }
  // getYMin = tagData => {
  //   if (tagData.ymin !== null) {
  //     return tagData.ymin
  //   } else {
  //     return 0
  //   }
  // }
  // getYMax = tagData => {
  //   if (tagData.ymax !== null) {
  //     return tagData.ymax
  //   } else {
  //     switch (tagData.tag) {
  //       case 'spsr':
  //         return 20
  //       case 'psr':
  //         return 260
  //       case 'temp':
  //         return 500
  //       case 'hum':
  //         return 100
  //       case 'puls':
  //         return 1000
  //       case 'dist':
  //         return 60
  //       case 'rot':
  //         return 10000
  //       default:
  //         return 30
  //     }
  //   }
  // }
  get display() {
    const options =
      this.displayOptions.find((o) => o.value === this.displayUnits) ||
      this.displayOptions[0]
    return {...options, render: (v) => (v === null ? null : options.render(v))}
  }
  get displayOptions()  {
    return getDisplayOptions(this.tag)
  }

  getClosestPoint(time) {
    const index = findClosestPoint(time, this.data)
    if (isNumber(index)) {
      const [time, value] = this.data[index]
      return {time, value, index}
    } else {
      return {time: null, value: null, index: null}
    }
  }
}

export const labels = {
  psr: 'Pressure',
  spsr: 'Fluid Level',
  dspsr: 'Fluid Level',
  rot: 'Rotation',
  vib: 'Vibration',
  dist: 'Radar Fluid Level',
  sonr: 'Sonar Fluid Level',
  hum: 'Humidity',
  _v: 'Internal Battery Level',
  _t: 'Internal Device Temperature',
  _csq: 'Signal Quality',
  puls: 'Flow',
  pult: 'Flow Test',
  temp: 'Sensor Temperature',
  pdiff: 'Differential Pressure',
  hpres: 'Vapor Pressure',
  tpres: 'Barometric Pressure',
  htemp: 'Vapor Temp',
  ttemp: 'Transmitter Temp',
  volt: 'Voltage',
  flash: 'Flasher',
  swt: 'Switch',
  flw: 'Flow meter',
  default: 'Sensor'
}

const Arr = ({children}) => children

const spsrDensityMetric = {
  oil: {label: 'API Gravity', key: 'api'},
  water: {label: 'Total Dissovled Solids (ppt)', key: 'tds'},
  chemical: {label: 'Specific Gravity', key: 'sg'}
}

const CalibrationSPRS = ({calibration, update, Tag, Tank}) => (
  <Arr>
    <InputRow>
      <InputWrapper label="Time">
        {formatDateTime(calibration.time)}
      </InputWrapper>
      <Input
        type="buttongroup"
        value={calibration.fluid}
        onChange={(e) => update({fluid: e.target.value})}
        label="Fluid"
        options={[
          {label: 'Oil', value: 'oil'},
          {label: 'Water', value: 'water'},
          {label: 'Chemical', value: 'chemical'}
        ]}
      />
    {calibration.fluid === 'chemical' && (
      <Input
        type="select"
        value={calibration.tank_name}
        onChange={(e) => update({ tank_name: e.target.value })}
        label="Tank"
        options={
          Tank.map((t) => ({ label: t, value: t }))   
        }
        />
    )}
    </InputRow>
    <InputRow>
    {calibration.fluid === 'chemical' && (       
      <Input
        type="buttongroup"
        value={calibration.unit}
        onChange={(e) => update({unit: e.target.value})}
        label="Calibrate In"
        options={[
          {label: 'Inches', value: 'inches'},
          {label: 'Gallons', value: 'gallons'},
        ]}
      />
    )}
    {calibration.fluid !== 'chemical' ? (
      <Input
        type={Tag.display.input || "float"}
        value={calibration.target}
        label="Actual Reading"
        onChange={(e) => update({target: e.target.value})}
      />
    ) : calibration.unit === 'gallons' ?
    ( <Input
      type="float"
      value={calibration.target}
      label="Actual Reading (gln)"
      onChange={(e) => update({target: e.target.value})}
      />
    ) : (
      <Input
      type="float"
      value={calibration.target}
      label="Actual Reading (in)"
      onChange={(e) => update({target: e.target.value})}
      />
    )
    }
      <Input
        type="float"
        value={calibration[spsrDensityMetric[calibration.fluid].key]}
        label={spsrDensityMetric[calibration.fluid].label}
        onChange={(e) =>
          update({
            [spsrDensityMetric[calibration.fluid].key]: e.target.value
          })
        }
      />
    </InputRow>
  </Arr>
  )


const CalibrationDefault = ({calibration, update, Tag}) => (
  <Arr>
    <InputRow>
      <InputWrapper label="Time">
        {formatDateTime(calibration.time)}
      </InputWrapper>
      <Input
        type={Tag.display.input || 'float'}
        value={calibration.target}
        label="Actual Reading"
        onChange={(e) => update({target: e.target.value})}
      />
    </InputRow>
  </Arr>
)

const CalibrationPulse = ({calibration, update, Tag}) => (
  <Arr>
    <InputRow>
      <InputWrapper label="Time">
        {formatDateTime(calibration.time)}
      </InputWrapper>
      <Input
        type="float"
        value={calibration.kFactor}
        label="K Factor"
        onChange={(e) => update({kFactor: e.target.value})}
      />
      <p>
      ** For fluid K-factor is pulses(rev)/gallon
      ** For gas K-factor is ft^3/pulse(rev)
      </p>
    </InputRow>
  </Arr>
)

const CalibrationRadar = ({calibration, update, Tag}) => (
  <Arr>
    <InputRow>
      <InputWrapper label="Time">
        {formatDateTime(calibration.time)}
      </InputWrapper>
      <Input
        type={'feetinches'}
        value={calibration.target ? calibration.target / 12 : null}
        label="Actual Tank Level"
        onChange={(e) => update({target: e.target.value * 12})}
      />
    </InputRow>
  </Arr>
)

const CalibrationFLW = ({calibration, update, Tag}) => (
  <Arr>
    <InputRow>
      <InputWrapper label="Time">
        {formatDateTime(calibration.time)}
      </InputWrapper>

      <Input
        type="float"
        value={calibration.orifice_diameter}
        label="Orifice Diameter (inches)"
        onChange={(e) => update({orifice_diameter: e.target.value})}
      />

      <Input
        type="float"
        value={calibration.pipe_diameter}
        label="Pipe Diameter (inches)"
        onChange={(e) => update({pipe_diameter: e.target.value})}
      />

      <Input
        type="float"
        value={calibration.sg}
        label="Specific gravity"
        onChange={(e) => update({sg: e.target.value})}
      />
    </InputRow>
  </Arr>
)

const Calibrations = {
  psr: CalibrationDefault,
  spsr: CalibrationSPRS,
  dspsr: CalibrationSPRS,
  puls: CalibrationPulse,
  pult: CalibrationPulse,
  dist: CalibrationRadar,
  sonr: CalibrationRadar,
  flw: CalibrationFLW,
  default: null
}
