import React, { useState } from 'react'
import * as compose from 'lodash/flowRight'
import {graphql} from 'react-apollo'
import { gql } from 'graphql.macro'
import Loading from 'Components/Loading'

import './index.scss'

export const GET_ALARM_CHECK = gql`
  query GET_ALARM_CHECK {
    me {
        id
        isSuperUser
    }
    alarmChecker {
        id
        companyName  
    }
  }
`

const AlarmsSet = ({data: {loading, error, me, alarmChecker}}) => {
    const [group, setGroup] = useState('')

    const selectGroup = (e) => {
        setGroup(e.target.value)
    }

    if (error) return 'error'
    if (loading) return <Loading />

    const isSuperUser = me?.isSuperUser
    const isSpecificUser = me?.id === 12034 || me?.id === 11394
    const devices = Object.groupBy(alarmChecker, (i) => i.companyName) || []

    return (
        <div className="list-container device-list">
        <div className="scrollable-container">
            <table>
                <thead>
                    <tr>
                        <th className='table-header-button'>Device ID(s) With No Alarms 
                            {(isSuperUser || isSpecificUser) && (<select onChange={selectGroup} value={group}>
                                {Object.keys(devices).sort().map(i => (
                                    <option value={i} key={i}>{i}</option>
                                ))}
                            </select>)}
                        </th>
                    </tr>
                </thead>
            </table>
            <li className='grouped-list'>
            {(isSuperUser || isSpecificUser) && group !== '' ?(devices[group]?.map((d, index) => (
                <a href={`#/devices/${d.id}`} key={d.id}>{index < devices[group]?.length - 1 ? d.id + ', ' : d.id } </a>
            ))) : (devices[Object.keys(devices).sort()[0]]?.map((d, index) => (
                <a href={`#/devices/${d.id}`} key={d.id}>{index < devices[Object.keys(devices).sort()[0]]?.length - 1 ? d.id + ', ' : d.id } </a>)))}
            </li>
        </div>
        </div>
    )
}

export default compose(graphql(GET_ALARM_CHECK))(AlarmsSet)