import React, {Component} from 'react'
import {dateTime as formatDateTime} from 'utils/format'
import downloadCSV from 'utils/downloadCSV'
import * as compose from 'lodash/flowRight'

import './ProductionReportTurbine.scss'
import {graphql} from 'react-apollo'
import { UPDATE_REPORT_HOUR } from './queries'

const getInitialState = (props) => ({
  monthOffset: 0,
  reportHour: props.Tag.reportHour || 6
})

class ProductionReportFlow extends Component {
  state = getInitialState(this.props)
  componentDidUpdate({Tag}) {
    if (Tag !== this.props.Tag || Tag.deviceID !== this.props.Tag.deviceID) {
      this.setState(getInitialState(this.props))
    }
  }

  getRows = () => {
    const now = new Date()
    //start at the 31st of the previous month so we can calculate the daily production on the 1st
    let time = new Date(
      now.getFullYear(),
      now.getMonth() - this.state.monthOffset,
      0,
      this.state.reportHour
    )
    let rows = []
    do {
      let reading
      const start = time.getTime() / 1000
      // subtract a second for end time
      const end =  time.getTime() / 1000 + 86400 - 1

      // get all data points within the time range of a day
      const filterArray = this.props.Tag.data
      .filter(([time]) => time >= start && time <= end)

      // necessary to stop errors
      if (filterArray.length !== 0)
        // get the average of all points in time range
        reading = filterArray.reduce((a, [, value]) => a + value, 0) / filterArray.length

      rows.push({
        time: formatDateTime(time) || false,
        rate: reading?.toFixed(2) || false,
        daily: false
      })
      if (rows.length > 1 && rows[rows.length - 1].rate !== false) {
        rows[rows.length - 1].daily = (rows[rows.length - 1].rate - rows[rows.length - 2].rate).toFixed(2)
      }
      time.setDate(time.getDate() + 1)
    } while (time.getDate() !== 1 || rows.length < 5)
    return rows.slice(1) //remove the first row which corresponds to the 31st of the previous month
  }

  updateReportHour = (reportHour) => {
    this.setState({reportHour})
    this.props.updateReportHour({
      variables: {
        deviceID: this.props.Tag.deviceID,
        tag: this.props.Tag.tag,
        reportHour: reportHour
      }
    })
  }

  downloadSpreadsheet = () => {
    const rows = this.getRows().map((r) => [
      r.time,
      r.rate !== false ? r.rate : '',
      r.daily !== false ? r.daily : ''
    ])
    downloadCSV(this.props.device.name + ' Production Report', rows, [
      'Date',
      'Daily Production',
      'Daily Change'
    ])
  }
  render() {
    const rows = this.getRows().map((r, i) => (
      <tr key={i}>
        <td className="date">{r.time}</td>
        <td>{r.rate !== false ? r.rate : '---'}</td>
        <td>{r.daily !== false ? r.daily : '---'}</td>
      </tr>
    ))

    return (
      <div className="production-report">
        <div className="toolbar">
          <div className="options">
          <div className="label">Report Hour:</div>
            <select
              id="barrels-per-inch-input"
              value={this.state.reportHour}
              onChange={(e) => {
                this.updateReportHour(parseInt(e.target.value))
              }}
            >
              <option value={0} label={'00:00'} />
              <option value={1} label={'01:00'} />
              <option value={2} label={'02:00'} />
              <option value={3} label={'03:00'} />
              <option value={4} label={'04:00'} />
              <option value={5} label={'05:00'} />
              <option value={6} label={'06:00'} />
              <option value={7} label={'07:00'} />
              <option value={8} label={'08:00'} />
              <option value={9} label={'09:00'} />
              <option value={10} label={'10:00'} />
              <option value={11} label={'11:00'} />
              <option value={12} label={'12:00'} />
              <option value={13} label={'13:00'} />
              <option value={14} label={'14:00'} />
              <option value={15} label={'15:00'} />
              <option value={16} label={'16:00'} />
              <option value={17} label={'17:00'} />
              <option value={18} label={'18:00'} />
              <option value={19} label={'19:00'} />
              <option value={20} label={'20:00'} />
              <option value={21} label={'21:00'} />
              <option value={22} label={'22:00'} />
              <option value={23} label={'23:00'} />
              <option value={24} label={'24:00'} />
            </select>
            <div className="label">Month:</div>
            <select
              value={this.state.monthOffset}
              onChange={(e) => this.setState({monthOffset: e.target.value})}
            >
              {new Array(12).fill(0).map((e, i) => {
                const date = new Date()
                date.setMonth(date.getMonth() - i)
                return (
                  <option key={i} value={i}>
                    {date.getMonth() + 1} / {date.getFullYear()}
                  </option>
                )
              })}
            </select>
          </div>
          <div>
            <span className="button" onClick={this.downloadSpreadsheet}>
              <span>Download Spreadsheet</span>
              <i className="fa fa-download" />
            </span>
          </div>
        </div>
        <div className="body">
          <table>
            <thead>
              <tr>
                <th className="date">Date</th>
                <th>Daily Production</th>
                <th>Daily Change</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default compose(graphql(UPDATE_REPORT_HOUR, {name: 'updateReportHour'}))(
  ProductionReportFlow
)