import React, {Component} from 'react'
import {Input, Select} from 'Components/Form'
import {gql} from 'graphql.macro'
import {Query, Mutation} from 'react-apollo'
import {GET_COMPANIES, GET_GROUPS_BY_COMPANY, GET_ME} from './queries'
import {route} from 'router'
import Loading from 'Components/Loading'

import './AddUser.scss'

const ADD_USER = gql`
  mutation ADD_USER(
    $firstName: String
    $lastName: String
    $companyID: Int
    $email: String
    $phoneNumber: String
    $countryCode: String
  ) {
    addUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      companyID: $companyID
      countryCode: $countryCode
    ) {
      user {
        id
        firstName
        lastName
        isManager
        isSuperUser
        permissions
        company {
          id
        }
      }
      error
    }
  }
`

export default class AddMember extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    countryCode: '+1',
    companyID: 0,
    error: null
  }
  render() {
    return (
      <Query query={GET_ME}>
        {({data, loading, error}) => {
          if (loading) return <Loading />
          if (error) return <div>error</div>
          const me = data.me
          const isSpecificUser = me?.id === 12034 || me?.id === 11394
          return (
            <div className="add-user">
              <div className="container">
                <div className="col person">
                  <div className="row">
                    <span className="fa-stack">
                      <i className="fa fa-circle fa-stack-2x"></i>
                      <i className="fa fa-user fa-stack-1x fa-inverse"></i>
                    </span>
                  </div>
                  <div className="row">
                    <p>
                      New users will have limited permissions for all devices by
                      default.
                    </p>
                    {this.state.error && (
                      <p className="error">{this.state.error}</p>
                    )}
                  </div>
                </div>
                <div className="col inputs">
                  <div className="flex-row first-name">
                    <div className="label">First Name: </div>
                    <div className="input">
                      <input
                        value={this.state.firstName}
                        onChange={(e) =>
                          this.setState({firstName: e.target.value})
                        }
                      />
                    </div>
                  </div>
                  <div className="flex-row first-name">
                    <div className="label">Last Name: </div>
                    <div className="input">
                      <input
                        value={this.state.lastName}
                        onChange={(e) =>
                          this.setState({lastName: e.target.value})
                        }
                      />
                    </div>
                  </div>
                  <div className="flex-row first-name">
                    <div className="label">Phone Number: </div>
                    <div className="input">
                      <Input
                        type="phone"
                        fieldOnly
                        value={this.state.phoneNumber}
                        onChange={(e) =>
                          this.setState({phoneNumber: e.target.value})
                        }
                      />
                    </div>
                  </div>
                  <div className="flex-row first-name">
                    <div className="label">Country Code: </div>
                    <div className="input">
                      <Select
                        options={[
                          {label: 'United States (+1)', value: '+1'},
                          {label: 'United Kingdom (+44)', value: '+44'},
                          {label: 'Sweden (+46)', value: '+46'},
                          {label: 'Singapore (+65)', value: '+65'},
                          {label: 'Thailand (+66)', value: '+66'},
                        ]}
                        fieldOnly
                        onChange={(e) =>
                          this.setState({
                            countryCode: e.target.value
                          })
                        }
                        value={this.state.countryCode}
                      />
                    </div>
                  </div>
                  <div className="flex-row first-name">
                    <div className="label">Email: </div>
                    <div className="input">
                      <input
                        value={this.state.email}
                        onChange={(e) => this.setState({email: e.target.value})}
                      />
                    </div>
                  </div>
                  {((data && data.me && data.me.isSuperUser) || isSpecificUser) && (
                    <Query query={GET_COMPANIES}>
                      {({loading, error, data}) => (
                        <div className="flex-row first-name">
                          <div className="label">Company: </div>
                          <div className="input">
                            <Input
                              type="select"
                              fieldOnly
                              value={this.state.companyID}
                              onChange={(e) =>
                                this.setState({
                                  companyID: e.target.value
                                })
                              }
                              options={[].concat([{label: '', value: 0}]).concat((
                                (data && data.companies) ||
                                []
                              ).filter((c) => {
                                if (me?.id === 12034) {
                                  return [ 10344, 10303, 10359, 10337, 10345, 10379, 10292, 10381, 10365, 10228, 10384].includes(c.id)
                                } else if (me?.id === 11394){
                                  return [10344, 10345, 10379, 10337, 10381].includes(c.id)
                                } else {
                                  return true
                                }
                              }).map((c) => ({value: c.id, label: c.name})))}
                            />
                          </div>
                        </div>
                      )}
                    </Query>
                  )}
                  <div className="row buttons">
                    <Mutation mutation={ADD_USER}>
                      {(addUser) => (
                        <button
                          className="primary"
                          disabled={
                            !(
                              this.state.firstName.length +
                              this.state.lastName.length
                            )
                          }
                          id="add-team-member-button"
                          onClick={async () => {
                            this.setState({error: null})
                            const res = await addUser({
                              variables: {
                                firstName: this.state.firstName,
                                lastName: this.state.lastName,
                                phoneNumber: this.state.phoneNumber,
                                countryCode: this.state.countryCode,
                                email: this.state.email,
                                companyID: this.state.companyID
                              },
                              refetchQueries: [
                                {
                                  query: GET_GROUPS_BY_COMPANY,
                                  variables: {
                                    companyID: me.isSuperUser
                                      ? this.state.companyID
                                      : me.company.id
                                  }
                                }
                              ]
                            })
                            const error =
                              res &&
                              res.data &&
                              res.data.addUser &&
                              res.data.addUser.error
                            const userID =
                              res &&
                              res.data &&
                              res.data.addUser &&
                              res.data.addUser.user &&
                              res.data.addUser.user.id
                            this.setState({error: error})
                            if (!error && userID) route(`/team/user/${userID}`)
                          }}
                        >
                          Add to Team
                        </button>
                      )}
                    </Mutation>
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      </Query>
    )
  }
}
